<template>
  <div class="epItem" @click="goEpDeatils">
    <!-- itemData.image itemData.name  itemData.category_string -->
    <div class="ep_image_box">
      <img :src="itemData.image" alt="">
    </div>
    <div class="epitem_info_box">
      <div class="epitem_name">
        {{ itemData.name  }}
      </div>
      <div class="epitem_row_box">
        <div class="epitem_row_title">
          地区:
        </div>
        <div class="epitem_row_text">
          {{ itemData.province+itemData.city }}
        </div>
      </div>
      <div class="epitem_row_box">
        <div class="epitem_row_title">
          规模:
        </div>
        <div class="epitem_row_text">
          {{ itemData.scale_name }}
        </div>
      </div>
      <div class="epitem_row_box">
        <div class="epitem_row_title">
          类型:
        </div>
        <div class="epitem_row_text">
          {{ itemData.category_string }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'epItem',
  props: {
    itemData: {
      type: Object,
      default: () => ({})
    },
    //list列表 查看   op 我的
    itemType: {
      type: String,
      default: 'list'
    }
  },
  data () {
    return {

    };
  },
  methods: {
    //前往企业详情页
    goEpDeatils () {
      this.$router.push({
        path: '/ep-details',
        query: {
          id: this.itemData.company_id ? this.itemData.company_id : this.itemData.id,
        }
      })
    },
  }
};
</script>

<style lang="scss">
.epItem {
  background: #ffffff;
  border-radius: 8px;
  padding: 22px 23px;
  box-shadow: 0px 0px 5px 0px #c5c8d5;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 0px 12px 0px #c5c8d5;
  }
  .ep_image_box {
    width: 84px;
    height: 84px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #e8e8e8;
    overflow: hidden;
    margin-right: 10px;
  }
  .epitem_info_box {
    .epitem_name {
      font-size: 15px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 10px;
    }
    .epitem_row_box {
      display: flex;
      margin-bottom: 6px;
      align-items: center;
      .epitem_row_title {
        color: #999999;
        font-size: 12px;
        margin-right: 4px;
      }
      .epitem_row_text {
        color: #333333;
        font-size: 12px;
      }
    }
  }
}
</style>
